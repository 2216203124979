html, body, #root{ height:100%; margin:0; }

body {
  background-color: rgb(255, 255, 255);
  font-size: 1.10em;
  font-family: 'Raleway', sans-serif;
  line-height: 1.15em;
}

header { 
  height: 100px; 
  background-color: rgb(236, 214, 147);
}

pre {
  padding: 5px;
  background: #F0F0F0;
  margin: 0px;
  line-height: 1.30em;
}

code {
  font-family: 'Source Code Pro', monospace;
}

h1 {
  font-family: 'Bree Serif', serif;
  line-height: 1em;
  color: rgb(34, 34, 31);
}

h2, h3, h4 {
  font-family: 'Bree Serif', serif;
  line-height: 1em;
  color: rgb(34, 34, 31);
}

a {
  font-weight: bold;
}

button {
  background-color: #3a583b; 
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 0px;
  cursor: pointer;
}

.disabledButton {
  opacity: 0.6;
  cursor: not-allowed;
}


footer .content {
  align-self: flex-end;
}

#root { 
  display:flex; 
  flex-direction:column; 
}

footer {
  background-color: rgb(94, 71, 29);
  color: rgb(229, 230, 220);
  display: flex;
  padding: 15px;
  margin-top: auto; 
  margin-right: 0px;
  height: 50px;
}

footer a {
  color: rgb(229, 230, 220);
  text-decoration: none;
}

.pushRight {
  margin-left: auto;
}

.quiz-app {
  margin: 0px;
}

.cursorPointer {
  cursor: pointer;
}

@media (min-width: 600px) {
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1200px;
  }
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

@media (min-width: 600px) {
  .column-small {
    max-width: 25%;
  }
}

.content {
  margin: 15px;
}

.quizList {
  list-style-type: none;
  padding: 0px;
}

.quizList li {
  padding: 5px;
  cursor: pointer;
}

.questionDone {
  background-color: rgb(191, 223, 162);
}

.questionCurrent {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(170, 170, 142);
}

.question {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(255, 255, 255);
}

.correct {
  animation-duration: 6s;
  animation-name: highlightCorrect;
  color: black;
}

@keyframes highlightCorrect {
  0% {
    background-color: white;
  }

  80% {
    background-color: rgb(205, 255, 161);
  }

  100% {
    background-color: white;
  }
}

.wrong {
  animation-duration: 6s;
  animation-name: highlightWrong;
  color: black;
}

@keyframes highlightWrong {
  0% {
    background-color: white;
  }

  80% {
    background-color: rgb(253, 181, 193);
  }

  100% {
    background-color: white;
  }
}

#status {
  padding: 3px;
}

.win img {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}